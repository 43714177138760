<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('menu.whoWeAre') }}
      </template>
    </RMPageHeader>
    <div class="row">
      <div class="col-lg-8">
        <p>{{ $t("about.aboutP1") }}</p>
        <p>{{ $t("about.aboutP2") }}</p>
        <p>{{ $t("about.aboutP3") }}</p>
        <p>
          <blockquote class="blockquote">
            <p class="mb-0">{{ $t("about.knowledgeIsPowerQuote") }}</p>
          </blockquote>
        </p>
      </div>
      <div class="col-lg-4">
        <h4 class="mb-3">{{ $t("about.followUs") }}</h4>
        <p>
          <a :href="facebookLink"><img src="@/assets/images/fb_01.webp" width="8%" class="m-r-10" alt="Facebook"/></a>
          <a v-if="instagramLink !== ''" :href="instagramLink"><img src="@/assets/images/instagram_01.webp" width="8%" alt="Instagram"/></a>
        </p>
      </div>
    </div>
    <div class="row mt-3 bg-light p-20">
      <div class="col-lg-6">
        <h4 class="mb-3">{{ $t("about.people") }}</h4>
        <div class="row text-center">
          <div class="col">
            <img src="@/assets/images/about_programer.webp" width="50%" alt="Nejc"/>
          </div>
        </div>
        <div class="row text-center mt-3">
          <div class="col">
            <h5>Nejc Potrebuješ</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <h4 class="mb-3">{{ $t("about.contact") }}</h4>
        <p>Nejc Potrebuješ s.p.<br/>Lesno Brdo 4a<br/>1354 Horjul<br/>{{ $t("countries.si") }}</p>
        <p><a :href=emailMailTo>{{this.infoEmail}}</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import {adminService} from "@/_services/admin.service";
import {getDomain} from "@/_helpers/domain_helper";
import RMPageHeader from "@/components/common/RMPageHeader.vue";

export default {
  name: "About",
  components: {RMPageHeader},
  computed: {
    domain() {
      return getDomain();
    },
    facebookLink() {
      if(this.domain === "resi-me.si"){
        return "https://www.facebook.com/wwwresimesi"
      }
      return "https://www.facebook.com/cyphiocom";
    },
    instagramLink() {
      if(this.domain === "resi-me.si"){
        return "";
      }
      return "https://www.instagram.com/cyphiogames"
    },
    infoEmail() {
      if(this.domain === "resi-me.si"){
        return "info@resi-me.si";
      }
      return "info@cyphio.com";
    },
    emailMailTo() {
      return "mailto:" + this.infoEmail;
    },
  },
  created() {
    adminService.getPageAbout().then((data) => {
      this.numRegisteredUsers = data.numRegisteredUsers;
      this.numCompletedGames = data.numCompletedGames;
    });
  }
};
</script>
