<template>
  <div>
    <GameMessages
        v-bind:all-filled-up="allFilledUp"
        v-bind:has-wrong-values="hasWrongValues"
        v-bind:game="game"
        v-bind:period="period"
    >
    </GameMessages>
    <h5>{{ description }}</h5>
    <table class="caesar-cypher">
      <tbody>
      <tr v-for="(row, idx) in grid" :key="idx">
        <caesar-cypher-cell
            v-for="(cell, idy) in row"
            :key="idy"
            v-bind:cell="cell"
            v-bind:row-index="idx"
            v-bind:col-index="idy"
            v-bind:grid="grid"
            v-bind:solution-achieved="solutionAchieved"
            @cellClick="cellClick"
        ></caesar-cypher-cell>
      </tr>
      </tbody>
    </table>
    <h5>{{ $t("games.common.encryptionAlphabet") }}</h5>
    <table class="alphabet">
      <tbody>
      <tr v-for="(row, idx) in gridSymbols" :key="idx">
        <td
            :class="getAlphabetClass(gridSymbols[idx][idy])"
            v-for="(cell, idy) in row"
            :key="idy"
            @click="setValue(gridSymbols[idx][idy])"
        >
          {{ gridSymbols[idx][idy] }}
        </td>
      </tr>
      </tbody>
    </table>
    <GameActions
        v-bind:game="game"
        v-bind:showed-actions="showedActions"></GameActions>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CaesarCypherCell from "@/components/games/caesar_cypher/CaesarCypherCell";
import GameMessages from "@/components/games/GameMessages.vue";
import GameActions from "@/components/games/GameActions.vue";

export default {
  name: "CaesarCypherGrid",
  props: {
    game: {
      type: String,
      default: "caesarCypher",
    },
    period: {
      type: String,
      default: "",
    },
    gameId: {
      type: String,
      default: "",
    },
    showedActions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    GameActions,
    CaesarCypherCell,
    GameMessages,
  },
  computed: {
    ...mapState("caesarCypherStore", {
      alphabet: (state) => state.alphabet,
      solutionAchieved: (state) => state.solutionAchieved,
      gridSymbols: (state) => state.gridSymbols,
      description: (state) => state.description,
      completed: (state) => state.completed,
      allFilledUp: (state) => state.allFilledUp,
      hasWrongValues: (state) => state.hasWrongValues,
      grid: (state) => state.grid,
    }),
    ...mapState({
      account: (state) => state.account,
    }),
  },
  methods: {
    getAlphabetClass(val) {
      let baseClass = "alphabet";

      if (val === "") {
        baseClass = baseClass + " rm-locked cursor-default";
      }

      return baseClass;
    },
    handleKeyPress(e) {
      e.preventDefault();
      let upperCaseKey = e.key.toUpperCase();
      if (upperCaseKey === "BACKSPACE") {
        this.$store.commit("caesarCypherStore/unsetValue");
      } else if (upperCaseKey === "ARROWUP") {
        this.$store.commit("caesarCypherStore/moveInDirection", 0);
      } else if (upperCaseKey === "ARROWDOWN") {
        this.$store.commit("caesarCypherStore/moveInDirection", 1);
      } else if (upperCaseKey === "ARROWLEFT") {
        this.$store.commit("caesarCypherStore/moveInDirection", 2);
      } else if (upperCaseKey === "ARROWRIGHT") {
        this.$store.commit("caesarCypherStore/moveInDirection", 3);
      } else {
        this.$store.commit("caesarCypherStore/setValue", upperCaseKey);
      }
    },
    setValue(val) {
      let upperCaseKey = val.toUpperCase();
      this.$store.commit(this.game + "Store/setValue", upperCaseKey);
    },
    cellClick(obj) {
      this.$store.commit(this.game + "Store/setSelected", obj);
    },
  },
  created() {
    this.$store.commit(this.game + "Store/initGrid", {
      period: this.period,
      gridId: this.gameId,
    });
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style lang="scss" scoped>
table.caesar-cypher {
  border-collapse: collapse;
  border: none;
  width: 100%;
  line-height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: x-large;
}

table.alphabet {
  margin-top: 30px;
  border-collapse: collapse;
  width: 100%;
  line-height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: large;
}

td.alphabet {
  border: 1px solid;
  text-align: center;
  width: 40px;
  height: 40px;
}

td.cursor-default {
  cursor: default;
}
</style>
