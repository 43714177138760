<script>

import {gamesService} from "@/_services";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import {initTasks, setState} from "@/_helpers/games/math-helper";
import {waitForMilliseconds} from "@/_helpers/misc_helper";
import {updateGameData} from "@/config/stores/common";
import {assignmentService} from "@/_services/assignment.service";

const mathStore = {
  namespaced: true,
  state: {
    assignment: null,
    game: null,
    gameId: null,
    isAssignment: false,
    tasks: [],
    currentTaskIdx: -1,
    period: null,
    loading: false,

    completed: false,
    solutionAchieved: false,
    allFilledUp: false,
    initialState: false,
    hasWrongValues: false,
    stateShown: false,
  },
  mutations: {
    async loadTasks(state, gameData) {
      const game = gameData.game;
      const period = gameData.period;
      const gameId = gameData.gameId;
      const isAssignment = Boolean(gameData.isAssignment);

      state.gameId = gameId;
      state.period = period;

      state.isAssignment = isAssignment;
      state.assignment = null;

      state.loading = true;
      state.completed = false;

      if(isAssignment) {
        assignmentService.getMyAssignment(gameId)
          .then((data) => {
            state.assignment = data.myAssignment
            const taskData = initTasks(state.assignment.tasks, isAssignment);
            setState(game, data.myAssignment.assignment_id, state, taskData);
            processResult(state);
            state.completed = state.assignment.completed;
          })
          .catch((error) => handleError(error, router, this._vm))
          .finally(() => {
            state.loading = false;
          });
      }
      else if(period !== "") {
        gamesService.get_periodic(game, period)
          .then((data) => {
            const taskData = initTasks(data.tasks);
            setState(game, data.gameId, state, taskData);
            processResult(state);
            state.completed = data.completed;
          })
          .catch((error) => handleError(error, router, this._vm))
          .finally(() => {
            state.loading = false;
          });
      } else if (gameId !== "") {
        gamesService.get(game, gameId)
          .then((data) => {
            const taskData = initTasks(data.tasks);
            setState(game, data.gameId, state, taskData);
            processResult(state);
          })
          .catch((error) => handleError(error, router, this._vm))
          .finally(() => {
            state.loading = false;
          });
      }
    },
    goToPreviousTask(state) {
      if(state.currentTaskIdx === 0) {
        return;
      }
      state.currentTaskIdx -= 1;
    },
    goToNextTask(state) {
      if(state.currentTaskIdx !== state.tasks.length - 1) {
        state.currentTaskIdx += 1;
      }
    },
    goToTask(state, data) {
      const {taskIdx} = data
      state.currentTaskIdx = taskIdx;
    },
    resetGrid(state) {
      let data = {
        gameId: state.gameId,
        game: state.game,
        taskId: "",
        taskSelected: "",
        taskCompleted: false,
        taskCorrect: false,
        reset: true,
        completed: state.completed
      }

      if (!state.completed) {
        updateGameData("math", data, state.period);
      }

      for(let i = 0; i < state.tasks.length; i++){
        state.tasks[i].completed = false;
        state.tasks[i].correct = false;
        state.tasks[i].selected = null;
      }
      state.allFilledUp = false;
      state.hasWrongValues = false;
      state.solutionAchieved = false;
      state.currentTaskIdx = 0;
    },
    async setSelected(state, data) {
      let skipped = false;

      const {taskId, value} = data;

      if (state.isAssignment) {
        processAssignmentResult(state, false, taskId, value)
      }
      else {
        state.tasks.forEach((task) => {
          if(task.taskId === taskId){
            if (state.isAssignment && task.selected !== null){
              skipped = true;
              return;
            }

            task.selected = value;

            task.completed = true;
            task.correct = task.answers.correct.toString() === value;

            processMathResult(state, false, taskId, task.selected, task.completed, task.correct);
          }
          return task;
        })
      }

      if(skipped){
        return;
      }

      await waitForMilliseconds(500);
      if(state.currentTaskIdx !== state.tasks.length - 1) {
        state.currentTaskIdx += 1;
      }

      processResult(state);
    },
  }
}

function updateAssignmentInfo(state, taskId, taskSelected) {
  if (state.completed) {
    return;
  }

  let data = {
    gameId: state.gameId,
    game: state.game,
    taskId,
    taskSelected,
    reset: false,
  }

  updateGameData("math", data, state.period).then((data) => {
    // update state data based on a response
    const responseData = data.data;

    if (!responseData.taskId) {
      return;
    }

    state.tasks.forEach((task, index) => {
      if(task.taskId === responseData.taskId) {
        state.tasks[index].selected = responseData.taskSelected;
        state.tasks[index].correct = Boolean(responseData.taskCorrect);
        state.tasks[index].completed = Boolean(responseData.taskCompleted);
      }}
    );

    state.completed = Boolean(responseData.completed);
  });
}

function updateMathInfo(state, taskId, taskSelected, taskCompleted, taskCorrect) {
  if (state.completed) {
    return;
  }

  let completed = state.solutionAchieved;
  if (state.isAssignment && areAllTasksSolved(state)) {
    completed = true;
  }

  let data = {
    gameId: state.gameId,
    game: state.game,
    taskId,
    taskSelected,
    taskCompleted,
    taskCorrect,
    reset: false,
    completed
  }

  updateGameData("math", data, state.period);
}

function processAssignmentResult(state, initialState, taskId, taskSelected) {
  // Do not invoke APIs if resetting already reset state
  if (initialState && state.initialState) {
    return;
  }

  state.initialState = initialState;

  processResult(state);

  if(!state.completed) {
    updateAssignmentInfo(state, taskId, taskSelected);
  }
}

function processMathResult(state, initialState, taskId, taskSelected, taskCompleted, taskCorrect) {
  // Do not invoke APIs if resetting already reset state
  if (initialState && state.initialState) {
    return;
  }

  state.initialState = initialState;

  processResult(state);

  if(!state.completed) {
    updateMathInfo(state, taskId, taskSelected, taskCompleted, taskCorrect);
    if(state.isAssignment && areAllTasksSolved(state)){
      state.completed = true;
    }

    if(state.solutionAchieved) {
      state.completed = true;
    }
  }
}

function processResult(state) {
  if(areAllTasksSolved(state)){
    state.allFilledUp = true;
    if(areAllTasksSolvedCorrectly(state)){
      state.hasWrongValues = false;
      state.solutionAchieved = true;
    }
    else{
      state.hasWrongValues = true;
      state.solutionAchieved = false;
    }
  }
  else {
    state.allFilledUp = false;
    state.hasWrongValues = false;
    state.solutionAchieved = false;
  }
}


function areAllTasksSolved(state) {
  return state.tasks.filter((task) => !task.completed).length <= 0;
}

function areAllTasksSolvedCorrectly(state) {
  return state.tasks.filter((task) => !task.completed || !task.correct).length <= 0;
}

export default mathStore;

</script>