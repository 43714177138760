<template>
  <div>
    <div class="row">
      <div class="col-lg-4">
        <h4 class="mb-3">{{ $t("about.ourStory") }}</h4>
        <p>{{ $t("about.ourStoryText") }}</p>
      </div>
      <div class="col-lg-4">
        <h4 class="mb-3">{{ $t("about.ourVision") }}</h4>
        <p>{{ $t("about.ourVisionText") }}</p>
      </div>
      <div class="col-lg-4">
        <h4 class="mb-3">{{ $t("about.followUs") }}</h4>
        <p>
          <a :href="facebookLink"><img src="@/assets/images/fb_01.webp" width="8%" class="m-r-10" alt="Facebook"/></a>
          <a v-if="instagramLink !== ''" :href="instagramLink"><img src="@/assets/images/instagram_01.webp" width="8%" alt="Instagram"/></a>
        </p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-center">
        <h2>{{ $t("about.ourOffer") }}</h2>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-4">
        <RMClickablePanel :title="this.$t('games.education')" link="/education">
          <template v-slot:panelBody>
            <div class="row">
              <div class="col">
                <p>{{ $t("landing.educational") }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <img
                    :src="imageLinks['educational']"
                    class="panel-image"
                    width="100%"
                    alt="Education"
                  />
              </div>
            </div>
          </template>
        </RMClickablePanel>
      </div>
      <div class="col-lg-4">
        <RMClickablePanel :title="this.$t('menu.mentorshipProgram')" link="/mentorship" header-class="alert alert-success">
          <template v-slot:panelBody>
            <div class="row p-b-10">
              <div class="col">
                <p>{{ $t("landing.mentorship") }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <img
                    :src="imageLinks['mentorship']"
                    class="panel-image"
                    width="100%"
                    alt="Mentorship"
                  />
              </div>
            </div>
          </template>
        </RMClickablePanel>
      </div>
      <div class="col-lg-4">
        <RMClickablePanel :title="this.$t('games.entertainment')" link="/entertainment">
          <template v-slot:panelBody>
            <div class="row p-b-10">
              <div class="col">
                <p>{{ $t("landing.entertainment") }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <img
                    :src="imageLinks['entertainment']"
                    class="panel-image"
                    width="100%"
                    alt="Entertainment"
                  />
              </div>
            </div>
          </template>
        </RMClickablePanel>
      </div>
    </div>
    <div class="row bg-light text-center p-20">
      <div class="col-lg-4">
        <div class="rm-milestone-number">
          {{ this.numRegisteredUsers }}
        </div>
        <div class="rm-milestone-text">
          {{ $t("about.numRegisteredUsers") }}
        </div>
      </div>
      <div class="col-lg-4">
        <div class="rm-milestone-number">
          {{ this.numGames }}
        </div>
        <div class="rm-milestone-text">
          {{ $t("about.numGames") }}
        </div>
      </div>
      <div class="col-lg-4">
        <div class="rm-milestone-number">
          {{ this.numCompletedGames }}
        </div>
        <div class="rm-milestone-text">
          {{ $t("about.numCompletedGames") }}
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-center">
        <h2>{{ $t("about.contactUs") }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <p v-html="contactUsString">
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {adminService} from "@/_services/admin.service";
import {getAllGames} from "@/_helpers/games_helper";
import {isUserLoggedIn} from "@/_helpers";
import {showSweetAlert} from "@/_helpers/api";
import {getDomain} from "@/_helpers/domain_helper";
import RMClickablePanel from "@/components/common/RMClickablePanel.vue";

export default {
  name: "Home",
  components: {RMClickablePanel},
  data() {
    return {
      numRegisteredUsers: 0,
      numGames: Object.keys(getAllGames()).length,
      numCompletedGames: 0,
      imageLinks: {
        entertainment: require("@/assets/images/entertainment_01.webp"),
        educational: require("@/assets/images/educational_01.webp"),
        mentorship: require("@/assets/images/mentorship_02.webp"),
      },
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    domain() {
      return getDomain();
    },
    facebookLink() {
      if(this.domain === "resi-me.si"){
        return "https://www.facebook.com/wwwresimesi"
      }
      return "https://www.facebook.com/cyphiocom";
    },
    instagramLink() {
      if(this.domain === "resi-me.si"){
        return "";
      }
      return "https://www.instagram.com/cyphiogames"
    },
    isNewUser() {
      return (
        isUserLoggedIn() &&
        "new_user" in this.account.user &&
        this.account.user.new_user
      );
    },
    creditAutoAdded() {
      return (
        isUserLoggedIn() &&
        "credit_auto_added" in this.account.user &&
        this.account.user.credit_auto_added > 0
      );
    },
    infoEmail() {
      if(this.domain === "resi-me.si"){
        return "info@resi-me.si";
      }
      return "info@cyphio.com";
    },
    contactUsString() {
      let baseStr = this.$t("about.haveQuestions");
      return baseStr.replace("{0}", `<a href="mailto:${this.infoEmail}">${this.infoEmail}</a>`);
    }
  },
  methods: {
    getLink(gameId) {
      return "/games/" + gameId;
    },
  },
  created() {
    adminService.getPageAbout().then((data) => {
      this.numRegisteredUsers = data.numRegisteredUsers;
      this.numCompletedGames = data.numCompletedGames;
    });
  },
  mounted() {
    if (this.isNewUser) {
      if (this.creditAutoAdded) {
        showSweetAlert("S-REGISTER-FIRST_LOGIN", this);
      }
      this.$store.commit("account/unsetNewUser");
    }
  }
};
</script>
