<template>
  <div class="text-center">
    <caesar-cypher-grid
        v-if="game === 'caesarCypher'"
        :key="period"
        :showed-actions="showedActions"
        :period="period"
    ></caesar-cypher-grid>
    <minesweeper-grid
        v-else-if="game === 'minesweeper'"
        key="minesweeper"
        :showed-actions="showedActions"
        :period="period"
    ></minesweeper-grid>
    <math-crossword-grid
        v-else-if="game === 'mathCrossword'"
        key="mathCrossword"
        :showed-actions="showedActions"
        :period="period"
    ></math-crossword-grid>
    <simple-maze-grid
        v-else-if="game === 'simpleMaze'"
        key="simpleMaze"
        :showed-actions="showedActions"
        :period="period"
    ></simple-maze-grid>
    <sudoku9x9
        v-else-if="game === 'sudoku'"
        key="sudoku"
        :showed-actions="showedActions"
        :period="period"
    ></sudoku9x9>
    <kakuro-grid
        v-else-if="game === 'kakuro'"
        key="kakuro"
        :showed-actions="showedActions"
        :period="period"
    ></kakuro-grid>
    <word-search-grid
        v-else-if="game === 'wordSearch'"
        key="wordSearch"
        :showed-actions="showedActions"
        :period="period"
    ></word-search-grid>
    <memory-grid
        v-else-if="game === 'memory'"
        key="memory"
        :showed-actions="showedActions"
        :period="period"
    ></memory-grid>
    <simple-math-grid
        v-else-if="game === 'simpleMath'"
        key="simpleMath"
        :showed-actions="showedActions"
        :period="period"
    ></simple-math-grid>
    <meme-grid
        v-else-if="game === 'meme'"
        key="meme"
        :showed-actions="showedActions"
        :period="period"
    ></meme-grid>
    <crossword-grid
        v-else-if="game === 'crossword'"
        key="crossword"
        :showed-actions="showedActions"
        :period="period"
    ></crossword-grid>
    <math-grid
        v-else-if="isEducationMathGame(game)"
        :key="game"
        :showed-actions="showedActions"
        :period="period"
        :game="game"
    ></math-grid>
  </div>
</template>

<script>
import {config} from "@/config/config";

import CaesarCypherGrid from "@/pages/games/CaesarCypherGrid";
import CrosswordGrid from "@/pages/games/CrosswordGrid";
import MathCrosswordGrid from "@/pages/games/MathCrosswordGrid";
import MinesweeperGrid from "@/pages/games/MinesweeperGrid";
import SimpleMazeGrid from "@/pages/games/SimpleMazeGrid";
import Sudoku9x9 from "@/pages/games/Sudoku_9x9";
import WordSearchGrid from "@/pages/games/WordSearchGrid";
import MemoryGrid from "@/pages/games/MemoryGrid";
import SimpleMathGrid from "@/pages/games/SimpleMathGrid";
import KakuroGrid from "@/pages/games/KakuroGrid";
import MemeGrid from "@/pages/games/MemeGrid";
import MathGrid from "@/pages/games/education/MathGrid.vue";
import {isEducationMathGame} from "@/_helpers/games_helper";

export default {
  name: "GamesPeriodic",
  methods: {isEducationMathGame},
  components: {
    MathGrid,
    MemoryGrid,
    CrosswordGrid,
    KakuroGrid,
    CaesarCypherGrid,
    MathCrosswordGrid,
    MinesweeperGrid,
    SimpleMazeGrid,
    Sudoku9x9,
    WordSearchGrid,
    SimpleMathGrid,
    MemeGrid
  },
  computed: {
    showedActions() {
      return ["resetGame"];
    },
    game() {
      return this.$route.params.game;
    },
    period() {
      return "daily";
    },
  },
  created() {
    const validGames = Object.keys(config.games.props);

    if (
        !validGames.includes(this.$route.params.game)
    ) {
      this.$router.push("/");
    }
  },
};
</script>
