<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('admin.status') }}
      </template>
    </RMPageHeader>
    <h3>{{ $t("admin.functionality") }}</h3>
    <hr/>
    <div class="row">
      <b-table hover :items="functionalityItems" :fields="functionalityTableFields">
        <template #cell(item_status)="data">
          <span v-if="data.value === 'OK'"><i class="fa fa-check fa-solved-rm" aria-hidden="true"></i></span>
          <span v-else class="rm-wrong">{{ data.value }}</span>
        </template>
      </b-table>
    </div>
    <h3>{{ $t("admin.content") }}</h3>
    <hr/>
    <div class="row">
      <b-table hover :items="contentItems" :fields="contentTableFields">
      </b-table>
    </div>
    <h3>{{ $t("admin.env_vars") }}</h3>
    <hr/>
    <div class="row">
      <b-table hover :items="envVarItems" :fields="envVarsTableFields">
      </b-table>
    </div>
    <h3>{{ $t("admin.settings") }}</h3>
    <hr/>
    <div class="row">
      <b-table hover :items="settings" :fields="settingsTableFields">
        <template #cell(type_)="data">
          <span v-if="data.item.type_ === 'bool'">
            <input type="button" class="btn btn-primary btn-sm" value="Toggle"
                   v-on:click="updateBoolSetting(data)"/>
          </span>
        </template>
      </b-table>
    </div>
    <h3>{{ $t("admin.actions") }}</h3>
    <hr/>
    <div class="row">
      <div class="col-3"><input type="button" class="btn btn-primary btn-sm" value="Send Test Email"
                                v-on:click="sendTestEmail()"/></div>
      <div class="col-9" v-html="sendEmailRes"></div>
    </div>
  </div>
</template>

<script>
import {adminService} from "@/_services/admin.service";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import RMPageHeader from "@/components/common/RMPageHeader.vue";

export default {
  name: "Status",
  components: {RMPageHeader},
  data() {
    return {
      functionalityItems: [],
      contentItems: [],
      envVarItems: [],
      settings: [],
      sendEmailRes: "",
    }
  },
  computed: {
    functionalityTableFields() {
      return [
        {
          key: "item",
          label: this.$t("admin.item"),
        },
        {
          key: "item_status",
          label: this.$t("admin.item_status"),
        },
      ];
    },
    contentTableFields() {
      return [
        {
          key: "item",
          label: this.$t("admin.item"),
        },
        {
          key: "item_value",
          label: this.$t("admin.item_value"),
        },
      ];
    },
    envVarsTableFields() {
      return [
        {
          key: "item",
          label: this.$t("admin.item"),
        },
        {
          key: "item_value",
          label: this.$t("admin.item_value"),
        },
        {
          key: "item_value_type",
          label: this.$t("admin.item_value_type"),
        },
      ];
    },
    settingsTableFields() {
      return [
        {
          key: "name",
          label: this.$t("admin.item"),
        },
        {
          key: "value",
          label: this.$t("admin.item_value"),
        },
        {
          key: "type_",
          label: this.$t("admin.action"),
        },
      ];
    }
  },
  methods: {
    updateBoolSetting(d) {
      let name = d.item.name;
      let currentValue = d.item.value;

      let newValue = false;
      if (currentValue === "") {
        newValue = true
      }

      let payload = {
        name: name,
        value: newValue
      }

      adminService.updateSetting(payload).then((data) => {
        this.settings[d.index].value = data.value;
      }).catch((error) => handleError(error, router, this._vm));
    },
    sendTestEmail() {
      this.sendEmailRes = ""
      
      adminService.sendTestEmail().then((data) => {
        this.sendEmailRes = JSON.stringify(data);
      }).catch((error) => {
        this.sendEmailRes = JSON.stringify(error);
      });
    }
  },
  created() {
    adminService.getPageStatus().then((data) => {
      this.functionalityItems = data.functionality;
      this.contentItems = data.content;
      this.envVarItems = data.envVars;
      this.settings = data.settings;
    }).catch((error) => handleError(error, router, this._vm));
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
}
</script>

<style scoped>

</style>