import VueRouter from "vue-router";
import {isUserLoggedIn, userHasRequiredRoles, wasUserLoggedIn} from "@/_helpers";
import {getCapitalizedDomain} from "@/_helpers/domain_helper";

const Home = () => import("../pages/Home.vue");
const Login = () => import("../pages/auth/Login.vue");
const Logout = () => import("../pages/auth/Logout.vue");

const GameDispatch = () => import("@/pages/games/GameDispatch");
const Games = () => import("@/pages/games/Games");
const GamesPeriodic = () => import("@/pages/games/GamesPeriodic");

const Profile = () => import("@/pages/user/Profile");
const CreditUsage = () => import("@/pages/user/CreditUsage");
const CreditBuy = () => import("@/pages/user/CreditBuy");

const About = () => import("@/pages/misc/About");
const QA = () => import("@/pages/misc/QA");
const Privacy = () => import("@/pages/misc/Privacy");

const Status = () => import("@/pages/admin/Status");
const Users = () => import("@/pages/admin/Users");

const Education = () => import("@/pages/Education");
const Entertainment = () => import("@/pages/Entertainment");
const Mentorship = () => import("@/pages/mentorship/Mentorship");
const MyStudents = () => import("@/pages/mentorship/MyStudents");
const MyStudent = () => import("@/pages/mentorship/MyStudent");
const MyAssignments = () => import("@/pages/mentorship/MyAssignments");
const MyAssignmentsArchive = () => import("@/pages/mentorship/archives/MyAssignmentsArchive");
const StudentGroups = () => import("@/pages/mentorship/StudentGroups");
const StudentGroup = () => import("@/pages/mentorship/StudentGroup");
const Assignment = () => import("@/pages/mentorship/assignments/Assignment");
const NewAssignment = () => import("@/pages/mentorship/assignments/NewAssignment");
const MyAssignmentDispatch = () => import("@/pages/mentorship/assignments/myAssignments/MyAssignmentDispatch");
const AssignmentsArchive = () => import("@/pages/mentorship/archives/AssignmentsArchive");

const routes = [
    {path: "/home", component: Home, public: true, requiredRoles: []},
    {path: "/login", component: Login, public: true, requiredRoles: []},
    {path: "/logout", component: Logout, public: true, requiredRoles: []},
    {path: "/games/:game", component: GameDispatch, public: true, requiredRoles: []},
    {path: "/education", component: Education, public: true, requiredRoles: []},
    {path: "/entertainment", component: Entertainment, public: true, requiredRoles: []},
    {path: "/games/:game/:id", component: Games, public: false, requiredRoles: []},
    {
        path: "/games-periodic/:game/:period",
        component: GamesPeriodic,
        public: true,
        requiredRoles: []
    },
    {path: "/my-profile", component: Profile, public: false, requiredRoles: []},
    {path: "/credit-usage", component: CreditUsage, public: false, requiredRoles: []},
    {path: "/credit-buy", component: CreditBuy, public: false, requiredRoles: []},
    {path: "/about", component: About, public: true, requiredRoles: []},
    {path: "/qa", component: QA, public: true, requiredRoles: []},
    {path: "/privacy", component: Privacy, public: true, requiredRoles: []},
    {path: "/status", component: Status, public: false, requiredRoles: ["admin"]},
    {path: "/users", component: Users, public: false, requiredRoles: ["admin"]},
    {path: "/mentorship", component: Mentorship, public: true, requiredRoles: []},
    {path: "/myStudents", component: MyStudents, public: false, requiredRoles: ["mentor"]},
    {path: "/myStudents/:studentId", component: MyStudent, public: false, requiredRoles: ["mentor"]},
    {path: "/myStudents/:studentId/newAssignment", component: NewAssignment, public: false, requiredRoles: ["mentor"]},
    {path: "/myStudents/:studentId/archive", component: AssignmentsArchive, public: false, requiredRoles: ["mentor"]},
    {path: "/myStudents/:studentId/assignments/:assignmentId", component: Assignment, public: false, requiredRoles: ["mentor"]},
    {path: "/studentGroups", component: StudentGroups, public: false, requiredRoles: ["mentor"]},
    {path: "/studentGroups/:studentGroupId", component: StudentGroup, public: false, requiredRoles: ["mentor"]},
    {path: "/studentGroups/:studentGroupId/newAssignment", component: NewAssignment, public: false, requiredRoles: ["mentor"]},
    {path: "/studentGroups/:studentGroupId/archive", component: AssignmentsArchive, public: false, requiredRoles: ["mentor"]},
    {path: "/studentGroups/:studentGroupId/assignments/:assignmentId", component: Assignment, public: false, requiredRoles: ["mentor"]},
    {path: "/myAssignments", component: MyAssignments, public: false, requiredRoles: []},
    {path: "/myAssignments/archive", component: MyAssignmentsArchive, public: false, requiredRoles: []},
    {path: "/myAssignments/:assignmentId", component: MyAssignmentDispatch, public: false, requiredRoles: []},
    {path: "*", redirect: "/home", public: true, requiredRoles: []},
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const allRoutes = routes.map((x) => x.path);
    const publicRoutes = routes.filter((x) => x.public).map((x) => x.path);

    let authRequired = true;
    let requiredRoles = [];
    // We have a route without params
    if (allRoutes.includes(to.path)) {
        authRequired = !publicRoutes.includes(to.path);
        const thisRoute = routes.find(r => r.path === to.path);
        if (!thisRoute) {
            console.log("Cannot find route for: " + to.path);
        }
        else {
            requiredRoles = thisRoute.requiredRoles;
        }
    }
    // Is this dynamic route?
    else {
        if (to.matched !== null && to.matched.length > 0) {
            let matchedRoute = to.matched[0].path;
            if (allRoutes.includes(matchedRoute)) {
                authRequired = !publicRoutes.includes(matchedRoute);
                const thisRoute = routes.find(r => r.path === matchedRoute);
                if (!thisRoute) {
                    console.log("Cannot find route for: " + matchedRoute);
                }
                else {
                    requiredRoles = thisRoute.requiredRoles;
                }
            }
        }
    }

    const domain = getCapitalizedDomain();
    if (domain.toUpperCase().includes("CYPHIO")){
        document.querySelector('meta[name="description"]').setAttribute("content", "Mind Games");
        document.querySelector('meta[name="keywords"]').setAttribute("content", "Cyphio.com, Cyphio, online games, mind games, sudoku");
    }
    else {
        document.querySelector('meta[name="description"]').setAttribute("content", "Spletna stran za reševanje miselnih iger");
        document.querySelector('meta[name="keywords"]').setAttribute("content", "Reši me, resi-me.si, Reševanje iger, Spletno reševanje iger, Miselne igre, Dnevne igre, Tedenske igre, online igre, online miselne igre");
    }

    document.title = domain;

    if (to.path === "/login") {
        return next();
    }

    if (wasUserLoggedIn() && !isUserLoggedIn()) {
        return next("/login?reset=true");
    } else if (authRequired && !isUserLoggedIn()) {
        return next("/login");
    } else if (authRequired && requiredRoles.length > 0) {
        if (!userHasRequiredRoles(requiredRoles)) {
            return next("/");
        }
    }

    next();
});

export default router;
