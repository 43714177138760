<template>
  <RMPanel v-bind:title="this.$t('games.dispatch.newGame')" header-class="alert alert-success">
    <template v-slot:panelBody>
      <new-game-info
        :game="game"
        :is-loading="isLoading"
        :game-info="gameInfo"
        :credit="credit"
      ></new-game-info>
    </template>
  </RMPanel>
</template>

<script>
import NewGameInfo from "@/components/games/dispatch/NewGameInfo";
import {mapState} from "vuex";
import {isUserLoggedIn} from "@/_helpers";
import RMPanel from "@/components/common/RMPanel.vue";

export default {
  name: "NewGamePanel",
  components: {RMPanel, NewGameInfo},
  props: {
    game: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    gameInfo: {
      type: Object,
      default: function () {
        return {};
      },
    }
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    isUserLoggedIn() {
      return isUserLoggedIn();
    },
    credit() {
      if (isUserLoggedIn()) {
        return this.account.user.credit_amount;
      }
      return 0;
    },
  }
};
</script>
