<template>
  <div>
    <div class="row form-group m-b-10 justify-content-center" v-if="!isUserLoggedIn">
      <div class="alert alert-warning panel-alert m-b-10">{{ $t("games.dispatch.needToBeLoggedIn") }}</div>
    </div>
    <new-sudoku-settings
      v-if="game === 'sudoku'"
      :game="game"
      :is-loading="isLoading"
      :game-info="gameInfo"
      @priceChange="onPriceChange"
    ></new-sudoku-settings>
    <new-word-search-settings
      v-if="game === 'wordSearch'"
      :game="game"
      :is-loading="isLoading"
      :game-info="gameInfo"
      @priceChange="onPriceChange"
    ></new-word-search-settings>
    <new-math-crossword-settings
      v-if="game === 'mathCrossword'"
      :game="game"
      :is-loading="isLoading"
      :game-info="gameInfo"
      @priceChange="onPriceChange"
      @configChange="onConfigChange"
    ></new-math-crossword-settings>
    <new-minesweeper-settings
      v-if="game === 'minesweeper'"
      :game="game"
      :is-loading="isLoading"
      :game-info="gameInfo"
      @priceChange="onPriceChange"
    ></new-minesweeper-settings>
    <new-simple-maze-settings
      v-if="game === 'simpleMaze'"
      :game="game"
      :is-loading="isLoading"
      :game-info="gameInfo"
      @priceChange="onPriceChange"
    ></new-simple-maze-settings>
    <new-caesar-cypher-settings
      v-if="game === 'caesarCypher'"
      :game="game"
      :is-loading="isLoading"
      :game-info="gameInfo"
      @priceChange="onPriceChange"
      @configChange="onConfigChange"
    ></new-caesar-cypher-settings>
    <new-memory-settings
      v-if="game === 'memory'"
      :game="game"
      :is-loading="isLoading"
      :game-info="gameInfo"
      @priceChange="onPriceChange"
      @configChange="onConfigChange"
    ></new-memory-settings>
    <new-simple-math-settings
      v-if="game === 'simpleMath'"
      :game="game"
      :is-loading="isLoading"
      :game-info="gameInfo"
      @priceChange="onPriceChange"
      @configChange="onConfigChange"
    ></new-simple-math-settings>
    <new-meme-settings
      v-if="game === 'meme'"
      :game="game"
      :is-loading="isLoading"
      :game-info="gameInfo"
      @priceChange="onPriceChange"
      @configChange="onConfigChange"
    ></new-meme-settings>
    <new-game-settings
      v-if="isEducationMathGame(game)"
      :game="game"
      :is-loading="isLoading"
      :game-info="gameInfo"
      :default-config="defaultConfig"
      :assignment-mode="assignmentMode"
      @newPriceChange="onNewPriceChange"
      @configChange="onConfigChange"
    />
    <div v-if="!assignmentMode">
      <hr/>
      <div class="row form-group m-b-10">
        <div class="col justify-content-center" v-if="this.isUserLoggedIn">
          <div class="alert alert-grey panel-alert" v-html="gamePrice"></div>
        </div>
         <div class="col">
          <button
          type="button"
          :class="newGameButtonClass"
          @click="onStartGame"
          :disabled="isNewGameDisabled"
        >
          {{ $t("games.dispatch.startGameButton") }}
        </button>
         </div>

        <!-- <div class="col-md-1"></div>
        <button
          v-if="isUserLoggedIn"
          type="button"
          class="btn btn-success col-md-5 m-2"
          @click="onGetCarrots"
        >
          {{ $t("games.dispatch.getCarrots") }}
        </button>
        <button
          v-else
          type="button"
          class="btn btn-success col-md-5 m-2"
          @click="onLoginClick"
        >
          {{ $t("menu.logIn") }}
        </button>
        <div class="col-md-1"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {gamesService} from "@/_services";
import {getCarrotsStateString, isEducationMathGame} from "@/_helpers/games_helper";
import NewSudokuSettings from "@/components/games/sudoku/NewSudokuSettings";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import NewWordSearchSettings from "@/components/games/word_search/NewWordSearchSettings";
import NewMathCrosswordSettings from "@/components/games/crossword/NewMathCrosswordSettings";
import NewMinesweeperSettings from "@/components/games/minesweeper/NewMinesweeperSettings";
import NewSimpleMazeSettings from "@/components/games/simple_maze/NewSimpleMazeSettings";
import NewCaesarCypherSettings from "@/components/games/caesar_cypher/NewCaesarCypherSettings";
import NewMemorySettings from "@/components/games/memory/NewMemorySettings";
import NewSimpleMathSettings from "@/components/games/simple_math/NewSimpleMathSettings";
import NewMemeSettings from "@/components/games/meme/NewMemeSettings";
import {isUserLoggedIn} from "@/_helpers";
import NewGameSettings from "@/components/games/NewGameSettings.vue";

export default {
  name: "NewGameInfo",
  components: {
    NewGameSettings,
    NewCaesarCypherSettings,
    NewSimpleMazeSettings,
    NewMinesweeperSettings,
    NewMathCrosswordSettings,
    NewWordSearchSettings,
    NewSudokuSettings,
    NewMemorySettings,
    NewSimpleMathSettings,
    NewMemeSettings,
  },
  data() {
    return {
      gameDifficulty: "",
      gameConfig: {},
      hasEnoughCredit: false,
      gameCreating: false,
      regularPrice: null,
      currentPrice: null
    };
  },
  props: {
    game: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    gameInfo: {
      type: Object,
      default: () => {},
    },
    credit: {
      type: Number,
      default: 0.0,
    },
    assignmentMode: {
      type: Boolean,
      default: false,
    },
    defaultConfig: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    gamePrice() {
      return getCarrotsStateString(this.credit, this.currentPrice, this.regularPrice, this);
    },
    isUserLoggedIn() {
      return isUserLoggedIn();
    },
    isNewGameDisabled() {
      return this.isLoading || !this.isUserLoggedIn || !this.hasEnoughCredit || this.gameCreating;
    },
    newGameButtonClass() {
      let baseClass = "btn btn-primary w-100";
      if (this.isNewGameDisabled) {
        baseClass = baseClass + " rm-cursor-default";
      }
      return baseClass;
    },
  },
  methods: {
    isEducationMathGame,
    onConfigChange(value) {
      this.gameConfig = value;
      this.$emit("onConfigChange", {game: this.game, config: this.gameConfig})
    },
    onNewPriceChange(value) {
      this.hasEnoughCredit = true;
      this.currentPrice = value.current;
      this.regularPrice = value.regular;

      if (this.credit < this.currentPrice) {
        this.hasEnoughCredit = false;
      }
    },
    onPriceChange(value) {
      this.hasEnoughCredit = true;

      this.regularPrice = value.prices.regular;
      this.currentPrice = value.prices.current;
      this.gameDifficulty = value.id;

      if (this.credit < this.currentPrice) {
        this.hasEnoughCredit = false;
      }
    },
    onStartGame() {
      this.gameCreating = true;

      let gameData = {
        difficulty: this.gameDifficulty,
      };

      if (this.gameConfig !== {}) {
        gameData.config = this.gameConfig;
      }

      gamesService
        .create_game(this.game, gameData)
        .then((data) => {
          this.gameCreating = false;

          this.$store.commit("account/updateCredit", data.credit_amount);
          router.push(`/games/${this.game}/${data.game_id}`);
        })
        .catch((error) => handleError(error, router, this));

      this.gameCreating = false;
    },
    onGetCarrots() {
      router.push(`/credit-buy`);
    },
    onLoginClick() {
      router.push(`/login`);
    },
  },
};
</script>

<style scoped></style>
