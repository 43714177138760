<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('qa.title') }}
      </template>
    </RMPageHeader>
    <!-- begin row -->
    <div class="row">
      <!-- begin col-6 -->
      <div class="col-xl-12">
        <!-- begin nav-tabs-pills -->
        <b-tabs pills nav-class="mb-3" content-class="p-15 rounded bg-white mb-4">
          <b-tab active>
            <template slot="title">
              <span class="d-sm-none">{{ $t("qa.common") }}</span>
              <span class="d-sm-block d-none">{{ $t("qa.common") }}</span>
            </template>

            <h4 class="m-t-10">{{ $t("qa.qHowToLogin") }}</h4>
            <p>{{ $t("qa.aHowToLogin") }}</p>
            <hr/>
            <h4 class="m-t-10">{{ $t("qa.qWhatDoIGetWithLogin") }}</h4>
            <p v-html="$t('qa.aWhatDoIGetWithLogin')"></p>
            <hr/>
            <h4 class="m-t-10">{{ $t("qa.qWhatAboutMyPersonalData") }}</h4>
            <p v-html="$t('qa.aWhatAboutMyPersonalData')"></p>
          </b-tab>
          <b-tab>
            <template slot="title">
              <span class="d-sm-none">{{ $t("qa.games") }}</span>
              <span class="d-sm-block d-none">{{ $t("qa.games") }}</span>
            </template>

            <h4 class="m-t-10" v-html="$t('qa.qWhatAreDailyChallenges')"></h4>
            <p v-html="$t('qa.aWhatAreDailyChallenges')"></p>
          </b-tab>
        </b-tabs>
        <!-- end nav-tabs-pills -->
      </div>
      <!-- end col-12 -->
    </div>
    <!-- end row -->
  </div>
</template>

<script>
import RMPageHeader from "@/components/common/RMPageHeader.vue";

export default {
  name: "QA",
  components: {RMPageHeader}
}
</script>

<style scoped>

</style>
