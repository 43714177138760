export function getGameMenu() {
    return [
        {
            path: "/education",
            icon: "fa fa-th",
            title: "games.education",
        },
        {
            path: "/entertainment",
            icon: "fa fa-cube",
            title: "games.entertainment",
        }
    ]
}

export function getMenu(isLoggedIn) {
    let menuEntries = [
        {path: "/home", icon: "fa fa-home", title: "menu.home"},
    ];

    if (isLoggedIn) {
        menuEntries.push({path: "/credit-buy", icon: "fas fa-carrot fa-carrot-rm", title: "menu.buyCarrots"});
    }

    return menuEntries;
}

export function getBottomMenu() {
    return [
        {path: "/about", icon: "fa fa-user-secret", title: "menu.whoWeAre"},
        {path: "/qa", icon: "fa fa-question", title: "menu.support"},
        {
            path: "/privacy",
            icon: "fa fa-book",
            title: "menu.privacy",
        }
    ];
}

export function getMentorMenu() {
    return [
        {path: "/myStudents", icon: "fa fa-user", title: "menu.myStudents"},
        {path: "/studentGroups", icon: "fa fa-users", title: "menu.myStudentGroups"},
    ]
}

export function getMenteeMenu() {
    return [
        {path: "/myAssignments", icon: "fa fa-tasks", title: "menu.myAssignments"},
    ]
}

export function getMentorshipMenu() {
    return [
        {path: "/mentorship", icon: "fa fa-school fa-mentorship-rm", title: "menu.mentorshipProgram"},
    ]
}