<template>
  <div>
    <GameMessages
        v-bind:all-filled-up="solutionAchieved && completed"
        v-bind:has-wrong-values="false"
        v-bind:game="game"
        v-bind:period="period"
    >
    </GameMessages>
    <h5>{{ $t("games.games.minesweeper.numberOfMines") }}: {{ numMines }}</h5>
    <table class="minesweeper">
      <tbody>
      <tr v-for="(row, idx) in grid" :key="idx">
        <minesweeper-cell
            v-for="(cell, idy) in row"
            :key="idy"
            v-bind:cell="cell"
            v-bind:row-index="idx"
            v-bind:col-index="idy"
        ></minesweeper-cell>
      </tr>
      </tbody>
    </table>
    <GameActions
        v-bind:game="game"
        v-bind:showed-actions="showedActions"></GameActions>
  </div>
</template>

<script>
import {mapState} from "vuex";
import MinesweeperCell from "@/components/games/minesweeper/MinesweeperCell";
import GameMessages from "@/components/games/GameMessages.vue";
import GameActions from "@/components/games/GameActions.vue";

export default {
  name: "MinesweeperGrid",
  components: {
    GameActions,
    MinesweeperCell,
    GameMessages,
  },
  props: {
    game: {
      type: String,
      default: "minesweeper",
    },
    period: {
      type: String,
      default: "",
    },
    gameId: {
      type: String,
      default: "",
    },
    showedActions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState("minesweeperStore", {
      grid: (state) => state.grid,
      numMines: (state) => state.numMines,
      solutionAchieved: (state) => state.solutionAchieved,
      completed: (state) => state.completed,
      killed: (state) => state.killed,
    }),
    ...mapState({
      account: (state) => state.account,
    }),
  },
  created() {
    this.$store.commit(this.game + "Store/initGrid", {
      period: this.period,
      gridId: this.gameId,
    });
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style scoped>
table.minesweeper {
  margin-top: 15px;
  border-collapse: collapse;
  border: 2px solid;
  width: 100%;
  line-height: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
}
</style>
