import {getLocalStorageUserItemName} from "@/_helpers/domain_helper";

export function getUserToken() {
    return JSON.parse(localStorage.getItem(getLocalStorageUserItemName()));
}


export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem(getLocalStorageUserItemName()));

    if (user && user.token) {
        return {Authorization: "Bearer " + user.token};
    } else {
        return {};
    }
}

export function corsHeader() {
    return {"Access-Control-Allow-Origin": "*"};
}

function parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function wasUserLoggedIn() {
    return getUserToken() != null;
}

export function isUserLoggedIn() {
    let user = JSON.parse(localStorage.getItem(getLocalStorageUserItemName()));
    if (user && user.token) {

        let parsed_token = parseJwt(user.token);

        let expiration_datetime = parsed_token.exp;
        let datetime_now = Math.floor(new Date().getTime() / 1000);

        return expiration_datetime - datetime_now > 0;
    }

    return false;
}

export function userHasRequiredRoles(roles) {
    let user = JSON.parse(localStorage.getItem(getLocalStorageUserItemName()));
    if (!user || !user.token || !user["roles"]) {
        return false;
    }

    const userRoles = user["roles"];
    if(userRoles.includes("admin")) {
        return true;
    }

    return roles.every(item => userRoles.includes(item));
}

export function isUserAdmin() {
    return userHasRequiredRoles(["admin"]);
}
