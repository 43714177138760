<template>
  <div>
    <GameMessages
        v-bind:all-filled-up="allFilledUp"
        v-bind:has-wrong-values="hasWrongValues"
        v-bind:game="game"
        v-bind:period="period"
    >
    </GameMessages>
    <h5 v-html="description"></h5>
    <table class="rm-table-crossword">
      <tbody>
      <tr v-for="(row, idx) in grid" :key="idx">
        <game-cell
            v-for="(cell, idy) in row"
            :key="idy"
            v-bind:row-index="idx"
            v-bind:col-index="idy"
            v-bind:grid="grid"
            v-bind:solution-achieved="solutionAchieved"
            @cellClicked="cellClick"
        ></game-cell>
      </tr>
      </tbody>
    </table>
    <GameActions
        v-bind:game="game"
        v-bind:showed-actions="showedActions"></GameActions>
  </div>
</template>

<script>
import {mapState} from "vuex";
import GameCell from "@/components/games/GameCell";
import GameMessages from "@/components/games/GameMessages.vue";
import GameActions from "@/components/games/GameActions.vue";

export default {
  name: "SimpleMathGrid",
  props: {
    game: {
      type: String,
      default: "simpleMath",
    },
    period: {
      type: String,
      default: "",
    },
    gameId: {
      type: String,
      default: "",
    },
    showedActions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    GameActions,
    GameCell,
    GameMessages,
  },
  computed: {
    ...mapState("simpleMathStore", {
      solutionAchieved: (state) => state.solutionAchieved,
      completed: (state) => state.completed,
      allFilledUp: (state) => state.allFilledUp,
      hasWrongValues: (state) => state.hasWrongValues,
      grid: (state) => state.grid,
      gameNumbers: (state) => state.gameNumbers,
      selectedCoords: (state) => state.selectedCoords,
    }),
    description() {
      return this.$t("games.games.simpleMath.markAllOf") + " <b>" + this.gameNumbers + "</b>";
    },
  },
  methods: {
    cellClick(obj) {
      if (!this.grid[obj.x][obj.y].marked) {
        this.$store.commit(this.game + "Store/setSelected", obj);
      }
    },
  },
  created() {
    this.$store.commit(this.game + "Store/initGrid", {
      period: this.period,
      gridId: this.gameId,
    });
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style scoped></style>
