<template>
  <div>
    <RMNotLoggedInBanner/>
    <b-tabs nav-class="mb-3" pills>
      <b-tab v-for="tab in tabs" v-bind:key="tab.id">
        <template slot="title">
          <span class="d-sm-none">{{ tab.title }} ({{Object.keys(tab.games).length}})</span>
          <span class="d-sm-block d-none">{{ tab.title }} ({{Object.keys(tab.games).length}})</span>
        </template>
        <div class="row">
          <div
              class="col-xs-12 col-sm-10 col-md-6 col-xl-4 col-xxl-3 col-xxxl-2"
              v-for="game in tab.games"
              v-bind:key="game.id"
          >
            <RMClickablePanel v-bind:title="getTitle(game.id)" :link="getLink(game.id)">
              <template v-slot:panelBody>
                <img
                      v-if="['flags', 'cities'].includes(game.id)"
                      :src="imageLinks[game.id + '_' + currentLocale]"
                      class="panel-image"
                      width="100%"
                  />
                  <img
                      v-else
                      :src="imageLinks[game.id]"
                      class="panel-image"
                      width="100%"
                  />
              </template>
            </RMClickablePanel>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  getEducationalArithmeticOperationsGames, getEducationalEquationsGames,
  getEducationalGames, getEducationalMeasurmentGames,
  getEducationalNumeralSystemsGames, getEducationalOtherGames
} from "@/_helpers/games_helper";
import RMClickablePanel from "@/components/common/RMClickablePanel.vue";
import RMNotLoggedInBanner from "@/components/common/RMNotLoggedInBanner.vue";

export default {
  name: "Education",
  components: {RMNotLoggedInBanner, RMClickablePanel},
  data() {
    return {
      imageLinks: {
        addition: require("@/assets/images/addition_01.webp"),
        subtraction: require("@/assets/images/subtraction_01.webp"),
        multiplication: require("@/assets/images/multiplication_01.webp"),
        division: require("@/assets/images/division_01.webp"),
        exponentiation: require("@/assets/images/exponentiation_01.webp"),
        roots: require("@/assets/images/roots_01.webp"),
        logarithms: require("@/assets/images/logarithms_01.webp"),
        lcm: require("@/assets/images/lcm_01.webp"),
        gcd: require("@/assets/images/gcd_01.webp"),
        roman: require("@/assets/images/roman_01.webp"),
        binary: require("@/assets/images/binary_01.webp"),
        octal: require("@/assets/images/octal_01.webp"),
        hexadecimal: require("@/assets/images/hexadecimal_01.webp"),
        time: require("@/assets/images/time_01.webp"),
        distance: require("@/assets/images/distance_01.webp"),
        mass: require("@/assets/images/mass_01.webp"),
        surface: require("@/assets/images/surface_01.webp"),
        volumeliquids: require("@/assets/images/volumeliquids_01.webp"),
        volumeobjects: require("@/assets/images/volumeobjects_01.webp"),
        flags: require("@/assets/images/flags_si_01.webp"),
        flags_si: require("@/assets/images/flags_si_01.webp"),
        flags_us: require("@/assets/images/flags_us_01.webp"),
        flags_de: require("@/assets/images/flags_de_01.webp"),
        cities_si: require("@/assets/images/cities_si_01.webp"),
        cities_us: require("@/assets/images/cities_us_01.webp"),
        cities_de: require("@/assets/images/cities_de_01.webp"),
        equationssimple: require("@/assets/images/equationssimple_01.webp"),
        equationsmultiple: require("@/assets/images/equationsmultiple_01.webp"),
      },
      gameList: getEducationalGames()
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    tabs() {
      return [
        {
          id: 'allGames',
          title: this.$t("games.allGames"),
          games: getEducationalGames()
        },
        {
          id: 'arithmeticOperations',
          title: this.$t("games.arithmeticOperations"),
          games: getEducationalArithmeticOperationsGames()
        },
        {
          id: 'equations',
          title: this.$t("games.equations"),
          games: getEducationalEquationsGames()
        },
        {
          id: 'numeralSystems',
          title: this.$t("games.numeralSystems"),
          games: getEducationalNumeralSystemsGames()
        },
        {
          id: 'measurements',
          title: this.$t("games.measurements"),
          games: getEducationalMeasurmentGames()
        },
        {
          id: 'other',
          title: this.$t("games.other"),
          games: getEducationalOtherGames()
        }
      ]
    },
    allGames() {
      return getEducationalGames();
    },
    arithmeticOperationsGames() {
      return getEducationalArithmeticOperationsGames();
    },
    equationsGames() {
      return getEducationalEquationsGames();
    },
    numeralSystemsGames() {
      return getEducationalNumeralSystemsGames();
    },
    measurementsGames() {
      return getEducationalMeasurmentGames();
    },
    otherGames() {
      return getEducationalOtherGames();
    },
    currentLocale() {
      return this.$i18n.locale;
    }
  },
  methods: {
    getLink(gameId) {
      return "/games/" + gameId;
    },
    getTitle(gameId) {
      return this.$t('games.games.' + gameId + '.name')
    }
  }
}
</script>