<template>
  <!-- Daily challenge -->
  <div v-if="period !== ''">
    <div v-if="allFilledUp" class="m-b-15">
        <div v-if="hasWrongValues" class="alert alert-danger p-4">
          {{ $t("games.common.solvedWrongly") }}
        </div>
        <div v-else class="alert alert-success p-4">{{ $t("games.common.solvedCorrectly") }}</div>
      </div>
  </div>
  <div v-else>
    <div class="messages">
      <h4>
        {{ $t("games.common.welcomeInGame") }}<b>{{ gameName }}</b
      >
        <game-instructions v-bind:game="game" v-if="!isEducationMathGame(game)"></game-instructions>
      </h4>
      <div v-if="allFilledUp">
        <div v-if="hasWrongValues" class="alert alert-danger p-4">
          {{ $t("games.common.solvedWrongly") }}
        </div>
        <div v-else class="alert alert-success p-4">{{ $t("games.common.solvedCorrectly") }}</div>
      </div>
    </div>
    <hr/>
  </div>
</template>

<script>
import GameInstructions from "@/components/games/_instructions/GameInstructions.vue";
import {isEducationMathGame} from "@/_helpers/games_helper";

export default {
  name: "GameMessages",
  methods: {isEducationMathGame},
  components: {
    GameInstructions,
  },
  props: {
    allFilledUp: {
      type: Boolean,
      default: false,
    },
    hasWrongValues: {
      type: Boolean,
      default: false,
    },
    game: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      default: "",
    },
  },
  computed: {
    gameName() {
      let nameConfig = "name";
      if (this.period !== "") {
        nameConfig = this.period.toLowerCase() + "Name";
      }
      return this.$t("games.games." + this.game + "." + nameConfig)
    }
  },
};
</script>

<style scoped>
.filled-up-message {
  font-size: medium;
}

</style>